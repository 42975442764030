import React from 'react'
import Layout from '../components/layout/Layout';
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import './careers.css'
import { Link } from 'gatsby';
import book from "../images/mockup.webp"
import { Helmet } from "react-helmet"
import { useState } from 'react';
import './ebook.css'
import { Box, Stack, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
export default function Example() {

    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        email: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleDownload = async () => {
        try {
            // Send email using Mailgun API
            const emailResponse = await fetch('https://api.mailgun.net/v3/growth.c4scale.com/messages', {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + btoa('api:43f4494a9f1c61d52e8c7272543a6841-a4da91cf-ff105d7c'),
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    from: 'info@c4scale.com',
                    to: 'marketing@c4scale.com',
                    subject: `EBook Downloaded from ${formData.companyName} by ${formData.name}`,
                    text: `Title of the ebook : 7 ways to reduce Data Costs during Economic Downturn \n Name: ${formData.name}\nCompany Name: ${formData.companyName}\nCompany Email :${formData.email}`,
                }),
            });

            if (!emailResponse.ok) {
                throw new Error('Network response was not ok' + emailResponse.statusText);
            }

            console.log('Email sent:', await emailResponse.json());

            // Download PDF from the provided link
            const pdfUrl = 'https://res.cloudinary.com/durti17dk/image/upload/v1716785619/blog-assets/CDO_Handbook_Navigating_Data_Costs_in_an_Economic_Downturn.pdf';
            const pdfResponse = await fetch(pdfUrl);
            const pdfBlob = await pdfResponse.blob();

            // Trigger download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(pdfBlob);
            link.download = '7 Ways to Cut Down Data Costs in an Economic Downturn.pdf';
            document.body.appendChild(link); // Append to body to make the link clickable
            link.click();
            document.body.removeChild(link);
            setFormData({
                name: '',
                companyName: '',
                email: ''
            }) // Remove the link after clicking
        } catch (error) {
            console.error('Error sending email or downloading PDF:', error);
        }
    };
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>7 ways to reduce Data Costs during Economic Downturn | E-Book - C4Scale</title>
                <meta name="description" content="7 ways to reduce Data Costs during Economic Downturn: Lessons learnt from managing Peta Bytes of Data E-Book" />
            </Helmet>
            <div>
                <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:px-16 md:py-3 md:pb-12 relative">
                    <div className="max-w-4xl mx-auto">
                        <div className=" bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
                            <div>

                                <h1 className="text-gray-900 font-bold text-3xl mb-2">7 ways to reduce Data Costs during Economic Downturn: Lessons learnt from managing Peta Bytes of Data</h1>
                                <div className='flex flex-col sm:flex-row justify-center items-center sm:justify-between gap-4'>
                                    <img src={book} className='h-[300px] w-[fit-content] sm:h-[480px] m-7'></img>
                                    <div className="form-container">
                                        <form onSubmit={(e) => { e.preventDefault(); handleDownload(); }}>
                                            <div className="form-group">
                                                <label>First Name*</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                    className="form-input"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Email*</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                    className="form-input"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Company Name*</label>
                                                <input
                                                    type="text"
                                                    name="companyName"
                                                    value={formData.companyName}
                                                    onChange={handleChange}
                                                    required
                                                    className="form-input"
                                                />
                                            </div>
                                            <button type="submit" className="download-button">Download Now</button>
                                        </form>
                                    </div>
                                </div>
                                <h3 className="text-3xl font-bold my-5">What's inside the ebook</h3>
                                <p className="text-21 leading-8 my-5">
                                    A one-stop guide that provides comprehensive and expert advice to CDOs, Head of Engineering (Data) & tech decision-makers for reducing data costs at scale. This eBook unites battle-tested lessons with hands-on experience brought to you directly from expertise gathered by managing planet scale data architectures & platforms in companies like Gojek, Ericsson, HMH etc</p>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Stack
                direction="row"
                justifyContent= "flex-end"
                width="100%"
                sx={{ marginTop: "20px", padding: "0 20px", marginBottom: "20px" }}
            >
                    <Link to="/blogs/cloud-based-generative-ai-10-ways-to-transform-business" style={{ textDecoration: 'none' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#1f2937' }}>
                            <Typography variant="h6" sx={{ fontSize: "18px" }}>Next</Typography>
                            <ArrowBackIosNewIcon sx={{ color: "#111827", fontSize: "18px", fontWeight: "bold", transform: 'rotate(180deg)' }} />
                        </Box>
                    </Link>
            </Stack>
            {/* <section style={{ padding: '0 4rem' }}>
        <CommentCount config={disqusConfig}  />
        <Disqus config={disqusConfig} />
      </section> */}
        </Layout>
    )
}
